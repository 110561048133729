import styles from "./style.module.scss";
import newsImg
    from "../../../assets/images/news/i-want-a-image-where-you-can-see-a-winter-tire-from-a-car-in-the-snow-and-a-part-of-a-car.jpeg";

export const MainPost = (
    {
        title,
        image,
        description,
        date,
        category
    }: {
        title: string,
        image: string,
        description: string,
        date: string,
        category: string
    }) => {
    return (
        <div className={styles.main_post}>
            <img src={newsImg} alt="news" className={styles.image}/>
            <div className={styles.content}>
                <div className={styles.header}>
                    <span className={styles.category}>{category}</span>
                    <span className={styles.date}>{date}</span>
                </div>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.description}>{description}</p>
            </div>
        </div>
    );
}